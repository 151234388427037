@import '~@csstools/normalize.css';
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.1/css/bulma.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  transition: 0.3s;
}

.hidden-by-default {
  display: none;
}

.is-tall {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.pinTileButton {
  display: block;
  position: absolute;
  top:-10px;
  left: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: gray;
  background-image: url(icons/push_pin_white_24dp.svg);
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
  cursor: pointer;
}

.pinTileButton:hover {
  background-image: url(icons/push_pin_black_24dp.svg);
  background-color: lightgray;
} 